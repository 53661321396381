import * as React from "react";
import { Footer1 } from "app/layouts/footer1";
import { Layout } from "app/components/layout";
import { Header1 } from "app/components/header";

const PrivacyPolicy: React.FC = () => (
  <Layout>
    <div className="bg-white pb-8 sm:pb-12 lg:pb-12 font-sans">
      <Header1 />
      <div className="max-w-3xl m-9">
        <h1>Privacy Policy</h1>
        <h2>Data privacy policy</h2>
        <p>
          Qameta Software s.r.o. values the protection of personal data, and we therefore place
          great emphasis on protecting your privacy. This Privacy Policy explains and clarifies how
          we, as data controller, treat our visitor’s and customer’s personal data according to (EU)
          2016/679, the General Data Protection Regulation (GDPR).
        </p>
        <p>
          This policy will apply in situations where Qameta Software s.r.o. (hereinafter "Qameta
          Software s.r.o.", "we", "us" and "our") treats personal information collected from the
          site. By using our site, you agree that your personal information is processed in
          accordance with this Privacy Policy.
        </p>
        <h2>What is personal data?</h2>
        <p>
          Personal data is any information relating to an identified or identifiable natural person;
          an identifiable natural person is a person who can be identified, directly or indirectly,
          in particular by reference to an identifier such as a name, an identification number,
          location data, an online identifier or to one or more factors specific to the physical,
          physiological, genetic, mental, economic, cultural or social identity of that natural
          person.
        </p>
        <h2>Categories of Personal data we collect</h2>
        <p>
          The personal information you provide is data that you actively provide to us by filling in
          different types of forms. When ordering products and services from Qameta Software s.r.o.,
          signing up for our programs, events, looking for vacant posts or submitting spontaneous
          applications, we may ask you to fill in forms where you provide us with your personal
          data.
        </p>
        <p>
          We collect how visitors use the site and the users experience of the information, through
          your IP address, information about your browser settings, and how your operating system
          uses the pages. This information is processed for the legal basis legitimate interest and
          is used to develop website features and content.
        </p>
        <h2>Purpose with processing of personal data</h2>
        <p>The information we collect from you can be used for any of the following purposes:</p>
        <ul>
          <li>
            <b>Give you a more personalized service.</b> Your information helps us better meet your
            individual needs. If you have enabled cookies in your browser, that information can be
            used to be able to provide a personalized user experience.
          </li>
          <li>
            <b>Improve and optimize our website.</b> We We are constantly striving to improve the
            supply of the site based on your information and views.
          </li>
          <li>
            <b>Improve our customer service.</b>Your information helps us provide you with more
            efficient customer service and support.
          </li>
          <li>
            <b>Process transactions.</b> Submit your goods and deliver the services you purchased
            from us.
          </li>
          <li>
            <b>Improve our communication.</b> The The e-mail address you provide can be used to send
            you information and updates about your order, company news, updates, information about
            other similar products and services, Qameta Software s.r.o.’s events and newsletters
            etc. Note: you can cancel your subscription at any time by following the instructions at
            the bottom of each email. If you have enabled cookies in your browser, your cookie
            information can be combined with data you've provided on our site to improve the
            customer communication.
          </li>
        </ul>
        <h2>Recipients of the personal data</h2>
        <p>
          We may disclose your personal information to all companies in our group (i.e.
          subsidiaries, our ultimate holding company and all its subsidiaries) insofar that it is
          reasonably necessary for the purposes stated in this privacy policy. We do not sell, trade
          or otherwise transfer your personal information to third parties. Exceptions are trusted
          third parties that help us to run the site, conduct our business, provide service, as long
          as these parties agree to keep the information confidential and not use it for other
          purposes that not agreed upon. We may also disclose your personal information where we
          find it necessary to comply with statutory requirements, maintain the site's policies or
          protect our or others' rights, property, or security. Non-personally identifiable
          information gathered from the website about visitors may, however, be provided to third
          parties for marketing, advertising or other use.
        </p>
        <p>
          We have agreements with third party service providers to help us get a better view of the
          site visitors. These service providers are not allowed to use the information they collect
          on our behalf for anything other than helping us operate and improve our business.
        </p>
        <p>
          If your personal data will be disclosed with any other recipients than stated in this
          Privacy Policy, we will provide you with information about that disclosure at the time
          collecting your personal data and obtaining your consent.
        </p>
        <h2>Cookies</h2>
        <p>
          Cookies are small files that a web site or service provider sends to the hard disk of your
          computer through the browser (if you allow) and which causes the site to recognize your
          browser and can retrieve and remember certain information.
        </p>
        <p>
          We use cookies to execute some essential functionalities and improve the user experience
          as well as compile statistics on traffic and communication on the site.
        </p>
        <p>
          Cookies are also used for communicational purposes to improve our customer communication
          as well as measuring the site analytics. Cookies tell us where our site's users have
          visited, help us measure and target our advertising and marketing activities as well as
          search results through internet search engines, and provide us with information about our
          users so that we can develop our online services.
        </p>
        <p>
          If you have enabled cookies in your browser, your cookie information can be used to
          improve our communication with you through emails, newsletters and such.
        </p>
        <p>
          You can also choose to turn off cookies in your browser, and/or delete stored cookies in
          your web browser. Note that blocking cookies may affect the functionality of the site.
        </p>
        <h2>How long we keep your personal information</h2>
        <p>
          We will only save your personal data for as long as it’s relevant for the purpose it was
          originally collected for. More specific information about retention time for processing of
          personal data will be provided to you when we collect personal and obtain your consent for
          specific purposes. When you, for example, sign up to our events we will provide you with
          the retention time for the processing of personal data for the event.
        </p>
        <h2>Your personal rights</h2>
        <p>
          You are entitled to request a report on what personal information we have about you by
          requesting a so-called registry extract. This can be made free of charge once a year. You
          also have the right to request that incorrect information about you shall be corrected, as
          well as the ability to request removal of your personal information. You have the right to
          withdraw your consent to processing of personal data and to prohibit the use of your
          personal information for direct marketing purposes.
        </p>
        <p>To submit any request, please contact us at sales@qameta.io or more information.</p>
        <p>
          You have the right to submit eventual complains about processing of your personal data to
          the national supervisory authority.
        </p>
        <h2>Transfer to third Country</h2>
        <p>
          Information we collect when you are visiting our website can be stored and processed and
          transferred between all the countries in which we operate to use the information in
          accordance with the Privacy Policy. However, Qameta Software s.r.o. will not transfer the
          personal data you provide us to countries outside the European Economic Area (EEA) without
          explicitly informing you about that transfer.
        </p>
        <h2>Links to third parties</h2>
        <p>
          We may, at our discretion, publish or offer third-party products on our website at our
          discretion. These third-party websites have their own, independent privacy rules. We
          therefore take no responsibility for the content and activities of these linked sites.
          However, as we want to protect the privacy of our site, we welcome comments on these
          websites.
        </p>
        <h2>Changes to this Privacy Policy</h2>
        <p>
          Qameta Software s.r.o. reserves the right to update or change this Policy at any time and
          you should visit this site regularly to obtain the latest version. Last updated:
          26/07/2021
        </p>
        <h2>How to contact us</h2>
        <p>
          If you have any questions regarding this privacy policy or our processing of personal
          data, or want to contact us regarding your personal data, please contact sales@qameta.io
        </p>
      </div>
      <Footer1 />
    </div>
  </Layout>
);

export default PrivacyPolicy;
